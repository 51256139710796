$Primary:    #bf9456;
$BackDrop:   rgba(0,0,0,0.7);
$Black:      #000000;
$Dark:       #0D0F11;
$DimGray:    #1a1d21;
$Gray:       #23262a;
$DarkGray:   #696969;
$Silver:     #9C9C9C;
$LightGrey:  #AAAAAA;
$Gainsboro:  #ced4da;
$WhiteSmoke: #FAFAFA;
$White:      #FFFFFF;
$Danger:     #b83b33;
$Success:    #198754;
$Link:       #aaaac8;
$LinearFirst:rgba(191,148,86,0.3);

$Grey800: 	 #0D0F11;
$Grey700: 	 #1a1d21;
$Grey600: 	 #23262a;
$Grey500: 	 #696969;
$Grey400: 	 #9C9C9C;
$Grey300: 	 #AAAAAA;
$Grey200: 	 #DCDCDC;
$Grey100: 	 #FAFAFA;

$secondary: $Grey300;