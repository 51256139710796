html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100dvh;
  scroll-margin-top: 86px;
  scroll-padding-top: 86px;


  #root {
    width: 100%;
    height: 100%;
  }

  textarea:focus, input:focus, select:focus {
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
  }
}

.tribo-barber{
  font-size: 60px;
  font-size: clamp(3.5rem, 5vh + 1rem, 5rem);
  font-family: 'Loubag', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 60px 0 0;
  box-shadow: inset -1px 0 0 rgba(0,0,0,0.1);
  background-color: $DimGray;
}

.color-primary {
  color: $Primary !important;
}

.color-white {
  color: $White !important;
}

.bg-color-dark {
  background-color: $Dark!important;
}

.bg-color-dim-gray {
  background-color: $DimGray !important;
}

.bg-color-grey {
  background-color: $Gray !important;
}

.border-primary {
  border: 5px solid $Primary;
}

.border-white {
  border: 1px solid $Gainsboro;
}

.border-radius-025 {
  border-radius: 0.25rem;
}

.border-radius-50 {
  border-radius: 50% !important;
}

.border-top-primary {
  border-top: 2px solid $Primary !important;
}

.image-small {
  width: 200px;
  border-radius: 5px;
}

.image-middle {
  width: 250px;
  border-radius: 5px;
}

input[type="file"] {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.label-file {
  padding: 20px 10px;
  width: 200px;
  background-color: $Primary;
  color: $DimGray;
  text-transform: uppercase;
  text-align: center;
  display: block;
  margin-top: 10px;
  cursor: pointer;
}

.modal-content {
  border-radius: 5px !important;
  border: none !important;
}