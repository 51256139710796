@import "../../styles/colors";

.div-alert {
  position: fixed;
  z-index: 2000;
  top: 40px;
  left: 0;
  right: 0;
  max-width: 100%;
  padding: 16px;
  animation: seila 0.2s linear;
  display: flex;
  justify-content: center;
}

@keyframes seila {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.div-alert-child {
  position: relative;
  display: flex;
}

.alert-component {
  cursor: pointer;
  box-shadow: 0 5px 20px 15px rgba(0, 0, 0, 0.20);
}

.alert-light{
  color: $Dark !important;
  background-color: $Gainsboro !important;
  border-color: $Dark !important;
}