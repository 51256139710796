@import 'styles/_colors.scss';
@import 'styles/_fonts.scss';
@import 'styles/_global.scss';

$primary: #bf9456;
$red: #b83b33;
$blue: #566d9f;
$yellow: #bf9456;
$white: $WhiteSmoke;

$link-color: $yellow;

$body-bg: $Dark;
$body-color: $WhiteSmoke;

$input-bg: $DimGray;
$input-disabled-bg: $Gray;
$input-border-color: $Dark;

$form-select-bg: $input-bg;
$form-select-disabled-bg: $input-disabled-bg;
$form-select-border-color: $input-border-color;

$card-bg: $DimGray;

$nav-link-color: $WhiteSmoke;

$nav-tabs-border-width: 0;
$nav-tabs-link-active-color: $Primary;
$nav-tabs-link-active-bg: $Gray !important;

$navbar-dark-hover-color: $WhiteSmoke;

.preloader {
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
	  transform: rotate(0deg);
	}
	70% {
	  transform: rotate(360deg);
	}
	100% {
	  transform: rotate(360deg);
	}
} 

@import '~bootstrap/scss/bootstrap.scss';